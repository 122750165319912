import { Observable, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";
import {
  userProfileRegisteredLanguageConfigurations,
  marketLocaleCurrencyMappingConfigurations,
  localToRegilyLanguageConfigurations,
  languageToRegisterConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { RegilyDifferenceCredentialsUsername } from "src/app/modules/registration/models/regily-difference/regily-difference-credentials-username.model";
import { RegilyDifferenceEmailCredentials } from "src/app/modules/registration/models/regily-difference/regily-difference-email-credentials.model";
import { PregmaticRegistrationRequest } from "src/app/modules/registration/models/pregmatic-registration-request.model";
import { RegilyRegistrationRequest } from "src/app/modules/registration/models/regily-registration-request.model";
import { RegilyDifference } from "src/app/modules/registration/models/regily-difference/regily-difference.model";
import { RegilyEventCallback } from "src/app/modules/registration/models/regily/regily-event-callback.model";
import { RegilyRegisterCallback } from "src/app/modules/registration/models/regily-register-callback.model";
import { RegilyWelcomeOffer } from "src/app/modules/registration/models/regily-welcome-offer.model";
import { RegilyRegistration } from "src/app/modules/registration/models/regily-registration.model";
import { LoginCredentials } from "src/app/modules/registration/models/login-credential.model";
import { TranslatedError } from "src/app/modules/registration/models/translated-error.model";
import { PhoneNumber } from "src/app/modules/registration/models/phone-number.model";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { LoginService } from "src/app/modules/auth/services/login.service";
import { MainService } from "src/app/modules/shared/services/main.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";
import { UtilityService } from "src/app/utility/utility.service";

@Injectable()
export class RegistrationService {
  // Strings
  languageCode: string = "";

  // Booleans
  isPhoneUniqueness: boolean = false;

  // Subscriptions
  languageCodeSubscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private emitterService: EmitterService,
    private commonService: CommonService,
    private loginService: LoginService,
    private mainService: MainService,
    private utility: UtilityService,
    private gtmService: GtmService,
    private router: Router
  ) {
    this.languageCode = this.utility.getLangCode();

    this.languageCodeSubscription = this.translationService.langCode$.subscribe((languageCode: string) => {
      this.languageCode = languageCode;
    });
  }

  /**
   * Opens third party Regily pop-up with
   * complete Registration flow in it..
   */

  openRegistrationHanlder(): void {
    if (this.commonService.isCountryBlockedSoft) {
      this.utility.openAccountComponent("registration-blocked");
      return;
    }

    this.gtmService.registrationGTMEvent("register", {
      event_type: "register",
      event_context: "start",
    });

    this.initiatedRegistrationCallBacks();

    if (window.regily) {
      window.regily.signUp.open();
    } else {
      console.log("Something went wrong with Registration flow.." + "please try again after sometime");
    }
  }

  setRegilyWelcomeOffer(): RegilyWelcomeOffer {
    let title_key: string = "registration.register_welcome_bonus_firstline";
    let affilateLang: string = this.utility.getAffCookies("affiliateLang");
    let getAffiliateValue: string = "";

    let description_key: string = "registration.register_welcome_bonus_secondline";

    let affiliateValuesList: string = this.translationService.instant("affiliateids.affiliateValue").split(",");

    if (this.utility.getAffCookies("affId")) {
      getAffiliateValue = this.utility.getAffCookies("affId").split("_")[0];
    }

    if (
      getAffiliateValue &&
      affilateLang &&
      affiliateValuesList &&
      affiliateValuesList.indexOf(getAffiliateValue) > -1 &&
      affilateLang === this.languageCode
    ) {
      title_key = `affiliate.${getAffiliateValue}_regily_line1`;
      description_key = `affiliate.${getAffiliateValue}_free_spins`;
    } else if (window.location.pathname.split("/")[2] === "live-casino") {
      title_key = "registration.register_livecasino_welcome_bonus_firstline";
      description_key = "registration.register_livecasino_welcome_bonus_secondline";
    }

    let bonusData: RegilyWelcomeOffer = {
      welcomeModal: false,
      bonusConfig: {
        title: this.translationService.instant(title_key),
        description: this.translationService.instant(description_key),
      },
    };

    return bonusData;
  }

  /**
   * Funcationality to set up a listener to regily regesteration
   * flow using regilyDoneCallback(Triggers after final step) &
   * regilyUpdateCallback(triggers after every step).
   */
  setRegilyDataSets(): void {
    let bonusData: RegilyWelcomeOffer = this.setRegilyWelcomeOffer();

    document.documentElement.dataset.regilyCustomData = JSON.stringify(bonusData);

    document.documentElement.dataset.regilyLanguage = localToRegilyLanguageConfigurations[this.languageCode];
    /**we have two variant on regily variant1 & varient2
     * 1. If use load website with /en-eu then varient2 need to pass for regilyVariant to load varient2
     * 2.For rest of all other languages(like /en-ca /en-nz /en-ro /fi-fi and /nb-no and /ja-jp ect..) varient1 need
     * to pass for regilyVariant for varient1
     * If nothing is passed the varient1 will be loaded as default(varient1 is the default on regily side).
     */
    document.documentElement.dataset.regilyVariant = this.languageCode === "en-eu" ? "v2" : "core";

    switch (this.languageCode) {
      case "en-ca":
      case "en-nz":
      case "hu-hu": {
        document.documentElement.dataset.regilyOtp = "false";
        break;
      }
      default: {
        document.documentElement.dataset.regilyOtp = environment.features.otp ? "true" : "false";
        break;
      }
    }
  }

  initiatedRegistrationCallBacks(): void {
    this.setRegilyDataSets();

    Object.defineProperty(window, "regilyDoneCallback", {
      value: (regilyDoneCallbackData: PregmaticRegistrationRequest) => {
        if (environment.features.showConsoles) {
          console.log("reggggggggggggggggggggggggggggggggg regilyDoneCallback", regilyDoneCallbackData);
        }

        if (environment.production) {
          if (
            regilyDoneCallbackData &&
            regilyDoneCallbackData.user &&
            regilyDoneCallbackData.user.phone &&
            regilyDoneCallbackData.user.phonePrefix
          ) {
            return new Promise((resolve, reject) => {
              this.validatePhoneUniqueness(regilyDoneCallbackData)
                .then(() => {
                  resolve(null);
                })
                .catch(() => {
                  const errorMessage: string = this.translationService.instant(
                    "registration.phoneNumber_already_exist"
                  );
                  let error: TranslatedError = new TranslatedError(errorMessage);
                  error.name = "custom-duplicatePhoneNumber";
                  error.title = this.translationService.instant("registration.phoneNumber_already_title");
                  this.isPhoneUniqueness = false;

                  reject(error);
                });
            });
          } else {
            const errorMessage: string = this.translationService.instant("registration.register_something_wrong");
            let error: TranslatedError = new TranslatedError(errorMessage);
            error.name = "something-wrong";
            error.title = this.translationService.instant("registration.register_failed");
            return Promise.reject(error);
          }
        } else {
          return this.registerDataToBackend(regilyDoneCallbackData);
        }
      },
    });

    Object.defineProperty(window, "regilyUpdateCallback", {
      value: (data: RegilyRegisterCallback, regilyDifference: RegilyDifference) => {
        if (environment.features.showConsoles) {
          console.log("reggggggggggggggggggggggggggggggggg regilyUpdateCallback data", data);
          console.log("reggggggggggggggggggggggggggggggggg regilyUpdateCallback data-diff", regilyDifference);
        }

        for (let property in regilyDifference) {
          for (let key in regilyDifference[property]) {
            if (key === "doneChapter" && regilyDifference[property][key]) {
              this.gtmService.sendRegilyAnalyticsEvent("event", "chapterDone", regilyDifference[property][key]);
            } else if (regilyDifference[property][key] && regilyDifference[property][key] !== "") {
              this.gtmService.sendRegilyAnalyticsEvent("event", "collectedData", key);
            }
          }
        }

        if (
          (regilyDifference as RegilyDifferenceEmailCredentials).credentials &&
          (regilyDifference as RegilyDifferenceEmailCredentials).credentials.email
        ) {
          // The email has been updated and validation will be triggered
          return this.validateEmailUniqueness({
            txtEmail: (regilyDifference as RegilyDifferenceEmailCredentials).credentials.email,
          });
        } else if (
          (regilyDifference as RegilyDifferenceCredentialsUsername).credentials &&
          (regilyDifference as RegilyDifferenceCredentialsUsername).credentials.username
        ) {
          return this.validateUserNameUniqueness({
            txtNickname: (regilyDifference as RegilyDifferenceCredentialsUsername).credentials.username,
          });
        } else {
          /*
            we need to resolve promise here..in order to handle other feilds
            if thet are not emials..
          */
          return Promise.resolve();
        }
      },
    });

    Object.defineProperty(window, "regilyEventCallback", {
      value: (data: RegilyEventCallback) => {
        if (data && data.action === "close-module" && !data.flowStatus.complete) {
          this.gtmService.registrationGTMEvent("register", {
            eventType: "register",
            event_context: "closed",
          });

          this.commonService.broadCastGameWindowRegilyPopUpTimer();
        }
      },
    });
  }

  /**
    @param signupData User final Data from the regily callback
    we have this data in our backend & return promise on success
    & reject on failure to regily in order to show proper message.
  */
  registerDataToBackend(signupData: PregmaticRegistrationRequest): Promise<any> {
    if (environment.features.showConsoles) {
      console.log("registerDataToBackend dddddddddddddddddddddddddd", signupData);
    }

    if (signupData) {
      delete signupData.user.birthdate;

      let userData: RegilyRegistration = {
        ...signupData.user,
        ...signupData.consent,
        ...signupData.mapped,
      };

      if (signupData.sanction && signupData.sanction.category) {
        userData.pepAndSanctionDetails = {
          category: signupData.sanction.category,
          flag: signupData.sanction.flag,
          program: signupData.sanction.program,
          source: signupData.sanction.source,
        };
      }

      userData.bannerId = this.utility.getAffCookies("bannerId");
      userData.affiliateId = this.utility.getAffCookies("affId");
      userData.trackerId = this.utility.getAffCookies("trackerId");
      userData.referenceUrl = this.utility.getAffCookies("referrer");

      // Temporary solution to map currency for hu-hu, before it will be changed in regily
      if (this.languageCode == "hu-hu" && environment.features.regilyCurrency.huf) {
        userData.currency = marketLocaleCurrencyMappingConfigurations[this.languageCode].currencyCode;
        userData.userLang = userProfileRegisteredLanguageConfigurations[this.languageCode];
      }

      // Temporary solution to map currency for en-row (but not in Thailand), before it will be changed in regily
      if (
        this.languageCode == "en-row" &&
        userData.country !== "TH" &&
        userData.country !== "ZA" &&
        environment.features.currency.enRow$ &&
        environment.features.regilyCurrency.enRow$
      ) {
        userData.currency = marketLocaleCurrencyMappingConfigurations[this.languageCode].currencyCode;
      }

      /**
       * we mapped user language based on country he selected while registration
       * instead of his locale or the language with which he landed
       */
      userData.userLang = languageToRegisterConfigurations[userData.country]
        ? languageToRegisterConfigurations[userData.country]
        : languageToRegisterConfigurations["others"];

      const requestData: RegilyRegistrationRequest = new RegilyRegistrationRequest(userData);

      return new Promise((resolve, reject) => {
        this.doRegistration(requestData)
          .toPromise()
          .then((registrationResponse: { success: boolean; externalEcrId: string }) => {
            if (registrationResponse && registrationResponse.success) {
              /**After Succesfull registration..we call login here
               * with user credentails provide on registration flow.
               * the after we resolve it as successfully(after successfull r)
               */
              this.gtmService.registrationGTMEvent("register", {
                eventType: "register",
                event_context: "finish",
                userid: registrationResponse.externalEcrId,
              });

              this.utility.deleteAffCookies();

              const logincredentials: LoginCredentials = {
                emailId: requestData.emailId,
                password: requestData.password,
              };

              this.loginService.doLogin(logincredentials).subscribe((loginResponse: { success: boolean }) => {
                if (loginResponse && loginResponse.success) {
                  resolve(null);
                  this.processAfterLogin();
                } else {
                  resolve(null);
                  this.router.navigate([`${this.languageCode}/casino`]);
                }
              });
            } else {
              const errorMessage: string = this.translationService.instant("registration.register_something_wrong");
              let error: TranslatedError = new TranslatedError(errorMessage);
              error.name = "something-wrong";
              error.title = this.translationService.instant("registration.register_failed");

              reject(error);
            }
          })
          .catch(() => {
            const errorMessage: string = this.translationService.instant("registration.register_something_wrong");

            let error: TranslatedError = new TranslatedError(errorMessage);
            error.name = "something-wrong";
            error.title = this.translationService.instant("registration.register_failed");

            reject(error);
          });
      });
    }
  }

  setLanguageToregily(language: string = "en"): void {
    document.documentElement.dataset.regilyLanguage = language;
  }

  doRegistration(regilyRegistrationRequest: RegilyRegistrationRequest): Observable<any> {
    return this.mainService.psPost(endpointConfigurations.doRegistration_url, regilyRegistrationRequest);
  }

  validateEmailUniqueness(emialObj): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainService
        .psPost(endpointConfigurations.uniqueEmail_url, emialObj)
        .toPromise()
        .then((data: { response: number }) => {
          if (data && data.response === 1) {
            resolve(null);
          } else {
            const errorMessage: string = this.translationService.instant("registration.email_already_message");
            let error: TranslatedError = new TranslatedError(errorMessage);
            error.name = "custom-duplicateAccount";
            error.title = this.translationService.instant("registration.email_already_title");
            error.action = "close";

            reject(error);
          }
        })
        .catch(() => {
          const errorMessage: string = this.translationService.instant("registration.email_already_error");
          let error: TranslatedError = new TranslatedError(errorMessage);
          error.name = "custom-duplicateAccount";
          error.title = this.translationService.instant("registration.email_already_error_title");

          reject(error);
        });
    });
  }

  validateUserNameUniqueness(emialObj): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainService
        .psPost(endpointConfigurations.uniqueUserName_url, emialObj)
        .toPromise()
        .then((data: { response: number }) => {
          if (data && data.response === 1) {
            resolve(null);
          } else {
            const errorMessage: string = this.translationService.instant("registration.username_already_message");
            let error: TranslatedError = new TranslatedError(errorMessage);
            error.name = "custom-duplicateUserName";
            error.title = this.translationService.instant("registration.username_already_title");
            error.action = "close";

            reject(error);
          }
        })
        .catch(() => {
          const errorMessage: string = this.translationService.instant("registration.username_already_error_message");
          let error: TranslatedError = new TranslatedError(errorMessage);
          error.name = "custom-duplicateUserName";
          error.title = this.translationService.instant("registration.username_already_title");

          reject(error);
        });
    });
  }

  validatePhoneUniqueness(regilyDoneCallbackData: PregmaticRegistrationRequest): Promise<any> {
    let phoneObj: PhoneNumber = {
      mobileNumber: regilyDoneCallbackData.user.phone,
      mobileIsdCode: regilyDoneCallbackData.user.phonePrefix.replace("+", ""),
    };

    return new Promise((resolve, reject) => {
      this.mainService
        .psPost(endpointConfigurations.checkUserPhoneAvailablity_url, phoneObj)
        .toPromise()
        .then((data: { status: string }) => {
          if (data && data.status === "SUCCESS") {
            this.registerDataToBackend(regilyDoneCallbackData)
              .then(() => {
                resolve(null);
              })
              .catch(() => {
                const errorMessage: string = this.translationService.instant("registration.register_something_wrong");
                let error: TranslatedError = new TranslatedError(errorMessage);
                error.name = "something-wrong";
                error.title = this.translationService.instant("registration.register_failed");

                reject(error);
              });
          } else {
            const errorMessage: string = this.translationService.instant("registration.phoneNumber_already_exist");
            let error: TranslatedError = new TranslatedError(errorMessage);
            error.name = "custom-duplicatePhoneNumber";
            error.title = this.translationService.instant("registration.phoneNumber_already_title");

            this.isPhoneUniqueness = false;

            reject(error);
          }
        })
        .catch(() => {
          const errorMessage: string = this.translationService.instant("registration.phoneNumber_already_exist");
          let error: TranslatedError = new TranslatedError(errorMessage);
          error.name = "custom-duplicatePhoneNumber";
          error.title = this.translationService.instant("registration.phoneNumber_already_title");

          this.isPhoneUniqueness = false;

          reject(error);
        });
    });
  }

  processAfterLogin(): void {
    if (this.commonService.getUserJurisdiction() === "estonia") {
      this.emitterService.broadCastLimitsRegulatoryPopup(true);
    }

    if (window.location.pathname.split("/")[2] === "live-casino") {
      this.router.navigate([`${this.languageCode}/live-casino`]);
    } else {
      this.router.navigate([`${this.languageCode}/casino`]);
    }

    this.utility.openAccountComponent("deposit");
    /**
     * Below code is to show a promotional video only for japanese user
     * after their first login & it's based on language code
     * not on ip address
     */

    if (
      this.languageCode === "ja-jp" &&
      /^\d+$/.test(this.translationService.instant("configdata.afterlogin-videoId"))
    ) {
      this.openPromotionalVideo();
    }
  }

  openPromotionalVideo(): void {
    this.emitterService.broadCastEnablePromotionVideo(true);
  }

  ngOnDestroy(): void {
    if (this.languageCodeSubscription) this.languageCodeSubscription.unsubscribe();
  }
}
